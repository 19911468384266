import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '@/shared/utils/requests';
import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '../utils';
import {RECORDS_PER_PAGE} from '@/shared/constants/citiesConstants';

const initialState = {
  error: null,
  isLoading: false,
  list: [],
  currentData: [],
};

export const slice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setCitiesList: (state, action) => {
      state.list = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    addCityToList: (state, action) => {
      state.list.push(action.payload);
    },
    updateCity: (state, action) => {
      const foundIndex = state.list.findIndex(
        ({id}) => id == action.payload.id
      );
      state.list[foundIndex] = {
        ...state.list[foundIndex],
        ...action.payload,
      };
    },
    deleteCity(state, action) {
      state.list = state.list?.filter(({id}) => id !== action.payload);
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

// Export actions
export const {
  hasError,
  startLoading,
  stopLoading,
  setCitiesList,
  setCurrentData,
} = actions;

// Selectors
export const getAllCities = (state) => state.cities.list;

export const getCityById =
  ({cityId}) =>
  (state) =>
    state.cities.list.find(({id}) => {
      return id === cityId;
    });

// Fetch Cities
export const fetchCities = () => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: '/cities',
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;

  dispatch(actions.setCitiesList(body?.cities));

  const initialData = body?.cities.slice(0, RECORDS_PER_PAGE);
  dispatch(actions.setCurrentData(initialData));
};

// Create Product
export const createNewCity = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: postRequest,
    endpoint: '/cities',
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else {
    dispatch(actions.addCityToList(body?.city));
    return body?.city;
  }
};

export const updateCity = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: patchRequest,
    endpoint: `/cities/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else {
    dispatch(actions.updateCity(body?.city));
    return body?.city;
  }
};

// Delete City
export const deleteCity = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: deleteRequest,
    endpoint: `/cities/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.deleteCity(data?.id));
};
