import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import {clearStore} from './utils';
// slices
import customizerReducer from './slices/customizer';
import usersReducer from './slices/users';
import productsReducer from './slices/products';
import bookingsReducer from './slices/bookings';
import fileReducer from './slices/file';
import citiesReducer from './slices/cities';
import feedbacksReducer from './slices/feedbacks';
import hotelsReducer from './slices/hotels';
import regionsReducer from './slices/regions';
import ferriesReducer from './slices/ferries';
import agentsReducer from './slices/agents';
import flagsReducer from './slices/flags';
import guidesReducer from './slices/guides';

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const customizerPersistConfig = {
  key: 'customizer',
  storage,
  keyPrefix: 'redux-',
};

const usersPersistConfig = {
  key: 'users',
  storage,
  keyPrefix: 'redux-',
};

const productsPersistConfig = {
  key: 'products',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['drawer'],
};

const bookingsPersistConfig = {
  key: 'bookings',
  storage,
  keyPrefix: 'redux-',
};

const filePersistConfig = {
  key: 'file',
  storage,
  keyPrefix: 'redux-',
};

const citiesPersistConfig = {
  key: 'cities',
  storage,
  keyPrefix: 'redux-',
};

const feedbacksPersistConfig = {
  key: 'feedbacks',
  storage,
  keyPrefix: 'redux-',
};

const hotelsPersistConfig = {
  key: 'hotels',
  storage,
  keyPrefix: 'redux-',
};

const regionsPersistConfig = {
  key: 'regions',
  storage,
  keyPrefix: 'redux-',
};

const ferriesPersistConfig = {
  key: 'ferries',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['portRoutesList', 'portCodesList'],
};

const agentsPersistConfig = {
  key: 'agents',
  storage,
  keyPrefix: 'redux-',
};

const flagsPersistConfig = {
  key: 'flags',
  storage,
  keyPrefix: 'redux-',
};

const guidesPersistConfig = {
  key: 'guides',
  storage,
  keyPrefix: 'redux-',
};

const appReducer = combineReducers({
  customizer: persistReducer(customizerPersistConfig, customizerReducer),
  users: persistReducer(usersPersistConfig, usersReducer),
  products: persistReducer(productsPersistConfig, productsReducer),
  bookings: persistReducer(bookingsPersistConfig, bookingsReducer),
  file: persistReducer(filePersistConfig, fileReducer),
  cities: persistReducer(citiesPersistConfig, citiesReducer),
  feedbacks: persistReducer(feedbacksPersistConfig, feedbacksReducer),
  hotels: persistReducer(hotelsPersistConfig, hotelsReducer),
  regions: persistReducer(regionsPersistConfig, regionsReducer),
  ferries: persistReducer(ferriesPersistConfig, ferriesReducer),
  agents: persistReducer(agentsPersistConfig, agentsReducer),
  flags: persistReducer(flagsPersistConfig, flagsReducer),
  guides: persistReducer(guidesPersistConfig, guidesReducer),
});

const rootReducer = (state, action) => {
  if (action.type === clearStore.type) {
    storage.removeItem('persist:root');
    storage.removeItem('persist:customizer');
    storage.removeItem('persist:users');
    storage.removeItem('persist:products');
    storage.removeItem('persist:bookings');
    storage.removeItem('persist:file');
    storage.removeItem('persist:cities');
    storage.removeItem('persist:feedbacks');
    storage.removeItem('persist:hotels');
    storage.removeItem('persist:regions');
    storage.removeItem('persist:ferries');
    storage.removeItem('persist:agents');
    storage.removeItem('persist:flags');
    storage.removeItem('persist:guides');

    localStorage.clear();
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export {rootPersistConfig, rootReducer};
