import {deleteRequest, getRequest, postRequest} from '@/shared/utils/requests';
import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '../utils';

const initialState = {
  error: null,
  isLoading: false,
  portCodesList: [],
  list: [],
  portRouteWithTrips: [],
  currentData: [],
};

export const slice = createSlice({
  name: 'ferries',
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setFerriesPortCodesList: (state, action) => {
      state.portCodesList = action.payload;
    },
    setPortRouteWithTrips: (state, action) => {
      state.portRouteWithTrips = action.payload;
    },
    setFerriesList: (state, action) => {
      state.list = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    deleteFerry(state, action) {
      state.list = state.list?.filter(({id}) => id !== action.payload);
    },
    addFerryToList: (state, action) => {
      state.list.push(action.payload);
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

// Export actions
export const {
  hasError,
  startLoading,
  stopLoading,
  setFerriesPortCodesList,
  setPortRouteWithTrips,
  setFerriesList,
  setCurrentData,
  addFerryToList,
} = actions;

// Fetch Ferries
export const fetchFerriesPortCodes = () => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: '/ferries/port-codes',
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;

  dispatch(actions.setFerriesPortCodesList(body));
};

// Fetch Ferry Routes
export const fetchPortRoutesByCode = (data) => async (dispatch) => {
  dispatch(actions.setPortRouteWithTrips(null));
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: `/ferries/port-routes/${data?.portFrom}/${data?.portTo}`,
    toastMessage: {success: {show: false}, error: {show: true}},
  });
  if (error) throw error;
  dispatch(actions.setPortRouteWithTrips(body));
};

// Fetch Ferry Route Quotes
export const fetchFerryRouteQuotes = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: `/ferries/quotes/${data?.routeId}`,
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (body) return body;
};

// Create Ferry Route
export const createFerryRoute = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: postRequest,
    payload: data,
    endpoint: `/ferries`,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else {
    dispatch(actions.addFerryToList(body));
    dispatch(actions.setPortRouteWithTrips([]));
    return body;
  }
};

// Fetch Ferries
export const fetchFerries = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: `/ferries`,
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;

  dispatch(actions.setFerriesList(body.ferries));
};

// Delete City
export const deleteFerry = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: deleteRequest,
    endpoint: `/ferries/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.deleteFerry(data?.id));
};

export const getFerriesPortCodes = (state) => state.ferries.portCodesList;

export const getPortRouteWithTrips = (state) =>
  state.ferries.portRouteWithTrips;

export const getFerryById =
  ({ferryId}) =>
  (state) =>
    state.ferries.list.find(({id}) => {
      return id === ferryId;
    });

export const getFerriesList = (state) => state.ferries.list;
