import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '../utils';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '@/shared/utils/requests';

const initialState = {
  error: null,
  isLoading: false,
  list: [],
  drawer: {
    heading: '',
    content: null,
  },
};

export const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setProductsList: (state, action) => {
      state.list = action.payload;
    },
    addProductToList: (state, action) => {
      state.list.push(action.payload);
    },
    updateProduct: (state, action) => {
      const foundIndex = state.list.findIndex(
        ({id}) => id == action.payload.id
      );
      state.list[foundIndex] = {
        ...state.list[foundIndex],
        ...action.payload,
      };
    },
    deleteProduct(state, action) {
      state.list = state.list?.filter(({id}) => id !== action.payload);
    },
    setDrawer(state, action) {
      state.drawer = action.payload;
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

// Selectors
export const getAllProducts = (state) => state.products.list;

export const getProductById =
  ({productId}) =>
  (state) =>
    state.products.list.find(({id}) => {
      return id === productId;
    });

export const getDrawer = (state) => state.products.drawer;

// Create Product
export const createNewProduct = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: postRequest,
    endpoint: '/products',
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else {
    dispatch(actions.addProductToList(body?.product));
    return body?.product;
  }
};

// Update Product
export const updateProduct = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: patchRequest,
    endpoint: `/products/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.updateProduct(body?.product));
};

// Publish Product
export const publishProduct = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: patchRequest,
    endpoint: `/products/${data?.id}/publish`,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.updateProduct(body?.product));
};

// Update Product
export const fetchProducts = () => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: `/products`,
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.setProductsList(body?.products));
};

// Update Product
export const deleteProduct = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: deleteRequest,
    endpoint: `/products/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.deleteProduct(data?.id));
};

// Set Drawer
export const setDrawer = (drawer) => (dispatch) =>
  dispatch(actions.setDrawer(drawer));
